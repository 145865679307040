import React, { useEffect, useState, useRef } from "react";
import { DashboardController } from "../../../controllers/dashboard-controller/DashboardController";
import { getScenes } from "../../../services/scenes-service/scenes.service";

import "./MainDashboardView.scss";

import {
	FullPlayer,
} from "@falconstudios/ns-player";
import { stringToSlug } from "../../../services/util-service/util.service";
import BannerSetViewFunc from "../../../components/BannerSetView/BannerSetViewFunc";
import { ROUTES } from "../../../services/navigation/navigation.routes";

import useUserStateChange from "../../../hook/useUserStateChange";
import MoviesScenesGrid from "../../../components/MoviesScenesGrid/MoviesScenesGrid";
import useInViewport from "../../../hook/useInViewport";
import { showJoinNowPage } from "../../../services/navigation/navigation.service";

import MostPopular from "./MostPopular/MostPopular";

const MainDashboardView = () => {
	const [state, setState] = useState({
		config: {},
		videos: [],
		viewAllLink: '',
		loading: true,
		error: false
	});
	const [logged] = useUserStateChange();

	const {
		config,
		videos = [],
		viewAllLink,
		loading,
		error
	} = state;

	const containerRef = useRef(null);
	const isVisible = useInViewport(containerRef);

	useEffect(() => {
		if (!isVisible) return;

		const config = DashboardController.getHomePageSpotlight();
		if (config && Object.keys(config).length !== 0) {
			let filter = Object.entries(config)[0];
			if (filter?.length) {
				const { viewAllLink, ...filterObject } = processFilters(filter);
				const [, config] = filter;

				getScenes(filterObject)
					.then((resp = {}) => {
						const { scenes = [] } = resp.data?.data;
						setState(prevState => ({
							...prevState,
							videos: scenes,
							config: config || {},
							viewAllLink
						}));
					})
					.catch(err => {
						console.log(err);
						setState(prevState => ({ ...prevState, error: true }));
					})
					.finally(() => setState(prevState => ({ ...prevState, loading: false })));
			}
		} else {
			setState(prevState => ({
				...prevState,
				loading: false
			}));
		}
		return () => {
			setState({});
		}

	}, [isVisible]);

	const processFilters = (filter) => {
		const [type, config] = filter;
		const { id, name } = config;
		const data = { sort_by: 'newest' };
		switch (type) {
			case "studio":
				const studioLink = ROUTES.studiosInfo.replace(':studioId', id).replace(':studioName', name);
				Object.assign(data, { viewAllLink: studioLink, studios_id: id });
				break;
			case "star":
				const starLink = ROUTES.starInfo.replace(':starId', id).replace(':starName', name);
				Object.assign(data, { viewAllLink: starLink, stars_id: id });
				break;
			case "theme":
				const themeLink = ROUTES.themeInfo.replace(':name', name);
				Object.assign(data, { viewAllLink: themeLink, tags_id: id });
				break;
			default:
				break;
		}
		return data;
	};

	const renderVideos = () => {
		const link = stringToSlug(viewAllLink);

		return (
			<MoviesScenesGrid
				title={videos.length !== 0 ? 'Spotlight on ' + config?.name || '' : ''}
				viewAllLink={{ text: 'View All', url: link }}
				data={videos}
				loading={loading}
				error={error}
				hideNavigation
				refProp={containerRef}
			/>
		);
	};




	const videodata = {
		//nsplayer
		ads: [],
		className: "Player",
		height: '100%',
		width: '100%',
		loop: true,
		fetchVideoThumbnails: undefined,
		isPreview: true, // if `true` than controls won't be displayed when play is pressed
		isHoverPreview: false,
		renderPreview: undefined,
		mapVideoThumbnails: undefined,
		onAddClick: undefined,
		onPreviewClick: undefined,
		playerConfiguration: undefined,
		progressBarColors: DashboardController.getPlayerConfig().progressBarColors,
		isLiked: () => false,
		isFavorite: () => false,
		playOnLoopWhileMouseOver: true,
		autoplay: true,
		inlinePlayer: true,
		//renderPreview: !blockPreview
		//	? renderPreview.bind(this, { overlayTitle, overlaySubtitle, overlayText })
		//	: undefined,
		//onPreviewClick: onBannerClick.bind(this, item),
		videos: [
			{
				id: undefined,
				sources: [
					{
						src: "https://spotlight.nakedcdn.com/qreel/Qreelcommercialedit-noaudio.mp4",
						type: "video/mp4",
					},
				],
				duration: undefined,
				playsinline: true,
				muted: true,
				thumbnail: [
					{
						src: "",
					},
				],
				poster: "",
				name: "",
			},
		],
	};

	return (
		<div className="MainDashboardView">

			{/* https://spotlight.nakedcdn.com/qreel/Qreelcommercialedit-noaudio.mp */}
			<div className="headerunit">
				<div className="qreelvideo-container">
					<div className="qreelvideotextwrap">
						<div className="qreelvideotextone">specially curated gay cinema</div>
						<div className="qreelvideotextmain">life is queer. watch it here.</div>
						<div className="qreelvideotextthree">film festival favorites to mainstream hits</div>
					</div>
					<FullPlayer data={videodata} /></div></div>

			<MostPopular />
			{renderVideos()}

			<BannerSetViewFunc bannerName='tour' />
			{!logged && <BannerSetViewFunc bannerName='nonMemberPromoAffiliate' />}
			{!logged && <BannerSetViewFunc bannerName='whatMembershipBringsYou' onClick={() => showJoinNowPage()} />}
		</div>
	);
};

MainDashboardView.displayName = 'MainDashboardView';

export default MainDashboardView;