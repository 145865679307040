import React from 'react';
import useUserStateChange from '../../hook/useUserStateChange';
import { env } from '../../env';
import { links } from '../../links';
import './SupportService.scss';
import { RenderConditionalComponent, PROPERTY_ID } from '../RenderConditionalComponent/RenderConditionalComponent';

const SupportServiceEmailAddressesOnly = () => {
    const [logged] = useUserStateChange();
    const classes = ['SupportServiceInfoOnly'];
    if (logged) {
        classes.push('UserLoggedIn');
    }
    const renderEpoch = (url) => {
        return url ? (
            <>
                <div className="Designator"> or visit EPOCH</div>
                <a href={url} rel="noreferrer" target="_blank" className="DesignatorValue">{url.replace('https://', '')}</a>
            </>
        ) : '';
    };
    return (
        <div className={classes.join(' ')}>
            <div className="InfoOnlyWrapper">
                <div className="SupportServiceInfoContent">
                    <div className="Designator">{env.REACT_APP_SITE_NAME}</div>
                    <a href={links.billingAndSupport} className="DesignatorValue">{env.REACT_APP_SUPPORT_EMAIL}</a>

                    <RenderConditionalComponent ids={[PROPERTY_ID.nakedSword, PROPERTY_ID.guerillaPorn]}>
                        {renderEpoch(env.REACT_APP_BILLING_SUPPORT_SITE)}
                    </RenderConditionalComponent>



                </div>
            </div>
        </div>
    );
};

export default SupportServiceEmailAddressesOnly;